@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {

	html,
	body {
		margin: 0;
		padding: 0;
		float: left;
		width: 100%;
		font-size: 16px;
		line-height: 100%;
		scroll-behavior: smooth;
	}

	body {
		font-family: 'Quicksand', sans-serif;
		color: #474747;
	}

	img {
		border: none !important;
		outline: none;
		max-width: 100%;
		display: inline-block;
	}

	a,
	a:hover {
		text-decoration: none
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		float: left;
		width: 100%;
		margin: 0 0 10px;
		line-height: 100%
	}

	h3 {
		margin-bottom: 0 !important;
		font-size: 24px;
	}

	h1 {
		font-size: 30px
	}

	h2 {
		font-size: 26px
	}

	h4 {
		font-size: 20px
	}

	h5 {
		font-size: 18px
	}

	h6 {
		font-size: 16px
	}

	p {
		float: left;
		width: 100%;
		margin: 0 0 10px;
		font-size: 16px;
		line-height: 120%;
	}

	input,
	input:focus {
		outline: none;
	}

	ul,
	ol {
		display: inline-block
	}
}

@layer utilities {
	.clip-triangle-desktop {
		clip-path: polygon(50% 40%, 0 0, 100% 0)
	}
	.clip-triangle-mobile {
		clip-path: polygon(50% 25%, 0 0, 100% 0)
	}
}

.pw {
	position: relative;
	max-width: 1280px;
	padding: 0 10px 0 10px;
	box-sizing: border-box;
	margin: 0 auto;
}

/* .pw-2{
	position:relative;
	width: 85%;
	padding: 0 10px 0 10px;
	box-sizing: border-box;
	max-width: 1400px;
	margin: 0 auto;
} */
/* .hidden {
	display: none
} */

/* .float-right{
	float:right !important;
} */
.float-left {
	float: left !important;
}

/* .align-right{
	text-align:right
} */
.align-left {
	text-align: left
}

.align-center {
	text-align: center
}

/* .sps{
	float:left;
	width: 100%;
	line-height: 100%;
} */
.spacer {
	float: left;
	width: 100%;
}

.button {
	display: inline-block;
	padding: 15px;
	cursor: pointer;
	font-family: 'Fredoka One', cursive;
	position: relative;
	border: 0;
	border-radius: 100px;
	font-size: 24px;
	text-transform: uppercase;
	letter-spacing: 2px;
}

.from_button.button {
	font-size: 20px;
	margin: 15px 0 30px;
}

#header {
	background: #1f6cb2;
	padding: 10px 0;
}

/* .alert_error_hidden {
	transform: translateY(-70px);
	transition-duration: 1s;
} */

.navbar-container {
	max-width: 1280px;
}

.orange_button {
	background: #f90;
}

.help-block-error {
	width: 100%;
	float: left;
	box-sizing: border-box;
	text-align: center;
	font-size: 14px;
	background: #FF1F5E;
	color: #FFF;
	padding: 10px;
	border-radius: 0 0 3px 3px;
}

.check_list {
	list-style: none;
	text-align: center;
}

.check_list li {
	position: relative;
	font-size: 24px;
	margin-bottom: 20px;
}

.check_list li span {
	display: inline-block;
	position: relative;
	font-weight: bold;
}

.check_list li span:before {
	content: '';
	position: absolute;
	width: 22px;
	height: 22px;
	background-image: url('https://cdn.fixat.mx/assets/home/bg/no-webp/check_icon.png');
	background-size: contain;
	background-repeat: no-repeat;
	top: -2px;
	left: -30px;
}

/* #welcome_form_002{
	margin-top: -20px;
} */

#section_002_after {
	height: 100px;
	background: #145d9f;
	margin-top: -100px;
}

#section_002_after:after {
	content: '';
	position: absolute;
	left: 0px;
	top: -132%;
	width: 100%;
	max-width: 100%;
	height: 280px;
	background: #145d9f;
	border-radius: 50%;
}

/* .main_form_button{
	width: 95%;
	background-color: #f90;
} */
.spinner {
	margin: 0 auto;
	width: 70px;
	text-align: center;
}

.spinner>div {
	width: 18px;
	height: 18px;
	background-color: #FFF;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner.viral-loops>div {
	width: 18px;
	height: 18px;
	background-color: #FF9900;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}

@-webkit-keyframes sk-bouncedelay {

	0%,
	80%,
	100% {
		-webkit-transform: scale(0)
	}

	40% {
		-webkit-transform: scale(1.0)
	}
}

@keyframes sk-bouncedelay {

	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	40% {
		-webkit-transform: scale(1.0);
		transform: scale(1.0);
	}
}

.help-block {
	float: left;
	width: 100%;
	position: relative;
	box-sizing: border-box;
	text-align: center;
	font-size: 14px;
	background: #FF1F5E;
	color: #FFF;
	padding: 3px 10px;
	margin-top: -2px;
	border-radius: 0 0 3px 3px;
}

.help-block:before {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 0 5px 6px 5px;
	border-color: transparent transparent #FF1F5E transparent;
	top: -6px;
	left: calc(50% - 5px);
}

.help-block-valid {
	float: left;
	width: 100%;
	position: relative;
	box-sizing: border-box;
	text-align: center;
	font-size: 14px;
	background: green;
	color: #FFF;
	padding: 3px 10px;
	margin-top: -2px;
	border-radius: 0 0 3px 3px;
}

.help-block-valid:before {
	content: '';
	position: absolute;
	border-style: solid;
	border-width: 0 5px 6px 5px;
	border-color: transparent transparent green transparent;
	top: -6px;
	left: calc(50% - 5px);
}

/* .arrow_right{
	font-size: 70px;
	color: #f68909;
} 
.anim-pulse {
	animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1);
}

@keyframes pulse {

	0%,
	100% {
		opacity: 1;
	}

	50% {
		opacity: .5;
	}
}*/
#img_017 {
	margin-bottom: 30px;
}

/* .webp #main_top {
	background: url('https://cdn.fixat.mx/assets/home/bg/webp/bg_001.webp');
	background-position: top;
	background-size: cover;
}

.no-webp #main_top {
	background: url('https://cdn.fixat.mx/assets/home/bg/no-webp/bg_001.jpeg');
	background-position: top;
	background-size: cover;
} */

#welcome_form_before_01 {
	overflow: hidden;
	height: 100px;
	background-color: #FFF;
}

#welcome_form_before_01::after {
	position: absolute;
	content: '';
	width: 100%;
	height: 200px;
	background: #e4ecf4;
	border-radius: 50%;
	top: 0;
	left: 0;
}

/* #welcome_form_after {
	overflow: hidden;
	height: 100px;
} */

#main_top:after {
	position: absolute;
	content: '';
	width: 100%;
	height: 100px;
	border-radius: 0 0 50% 50%;
	top: 100%;
	left: 0;
	@apply bg-paste-blue
}

/* .sticky-button{
  position: sticky;
  top: 0;
  background-color: yellow;
} */

#acerca-de-fixat::before {
	position: absolute;
	content: '';
	width: 100%;
	height: 220px;
	background: #0F4373;
	border-radius: 40%;
	top: -100px;
	left: 0;
	@apply ssm:h-0;
}

#acerca-de-fixat.servicio-contable::before {
	height: 180px;
	@apply ssm:hidden ssm:top-0;
}


#thankyou_before_faqs {
	overflow: hidden;
	background-color: #c4d86b;
	height: 100px;
}

#thankyou_before_faqs::before {
	position: absolute;
	content: '';
	width: 100%;
	height: 220px;
	background: #145d9e;
	border-radius: 50%;
	top: 0;
	left: 0;
}

#thankyou_gray_wave {
	display: none;
}

.faq-question::after {
	content: '>';
	cursor: pointer;
	position: absolute;
	right: 0;
	top: 0px;
	transform: rotate(-270deg);
	color: #717171;
	font-size: 22px;
	transition: all 0.4s ease-out;
}

.faq-open.faq-question::after {
	transform: rotate(-90deg);
}

/* #loader{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	z-index: 9999;
	display: none
}

#loader:after {
	content: '';
	position: absolute;
	display: inline-block;
	top: 50%;
	left: 50%;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	border-top: 5px solid #f68909;
	border-right: 5px solid #f68909;
	border-bottom: 5px solid #f68909;
	border-left: 5px solid transparent;
	-webkit-animation: cssAnimation 1.5s infinite linear;
	-moz-animation: cssAnimation 1.5s infinite linear;
	-o-animation: cssAnimation 1.5s infinite linear;
} */

/* @-webkit-keyframes cssAnimation {
	from {
		-webkit-transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(360deg);
	}
}

@-moz-keyframes cssAnimation {
	from {
		-moz-transform: rotate(0deg);
	}

	to {
		-moz-transform: rotate(360deg);
	}
}

@-o-keyframes cssAnimation {
	from {
		-o-transform: rotate(0deg);
	}

	to {
		-o-transform: rotate(360deg);
	}
} */
/*   -------------------- LOADING PAGE ----------------------*/
.loading_overlay {
	background-color: rgba(255, 255, 255, .5);
}

.loading_image {
	animation: rotate 0.6s infinite linear alternate;
}

/*   -------------------- Animations ----------------------*/
/* .fade-in{
	animation: fadeIn 0.3s ease-in both;
}

@keyframes fadeIn {
	from {
		opacity: 0;
		transform: translate3d(0, -100%, 0);
	}

	to {
		opacity: 1;
		transform: translate3d(0, 0, 0);
	}
} */
@keyframes rotate {
	0% {
		transform: rotate(-30deg);
	}

	100% {
		transform: rotate(30deg);
	}
}

.opinions_message_triangle::after {
	position: absolute;
	top: 17px;
	left: -7px;
	content: '';
	width: 0;
	height: 0;
	border-right: solid 10px rgba(255, 255, 255);
	border-bottom: solid 11px transparent;
	border-top: solid 0px transparent;
}

.bg-instagram {
	background: linear-gradient(88.49deg, #FEBF1F 0%, #C2049D 100%);
}

.swiper-pagination-fraction {
	color: #374151 !important;
	font-size: 14px !important;
	font-weight: 500 !important;
}

/* .swiper-pagination-bullets {
	top: 570px;
	display: flex;
	position: absolute;
} */

.mySwiper-opinions {
	padding-bottom: 90px !important;
	width: 100%;
}

.swiper-button-next,
.swiper-button-prev {
	background-color: white;
	border-radius: 100%;
	font-size: 22px;
	color: #1F6CB2 !important;
	width: 37px !important;
	height: 37px !important;
	top: 90% !important;
	margin-top: 20px !important;

}

.swiper-button-prev {
	left: calc(40% - 36px) !important;
}

.swiper-button-next {
	left: 59% !important;
}

.swiper-button-next::after,
.swiper-button-prev::after {
	content: "" !important;
}

/* .grecaptcha-badge { visibility: hidden!important} */

.buttons {
	display: inline-block;
	padding: 10px;
	cursor: pointer;
	line-height: 1.25;
	font-family: 'Fredoka One', cursive;
	position: relative;
	border: 0;
	border-radius: 100px;
	font-size: 24px;
	letter-spacing: 2px;
}

.navbar {
	display: inline-block;
	padding: 15px 5px;
	color: #FFF;
	letter-spacing: normal;
	font-size: 16px;
	cursor: pointer;
	margin: 0;
}

.navbar:first-child {
	margin-right: 0;
}

.navbar-landing {
	display: inline-block;
	padding: 15px 5px;
	color: #FFF;
	letter-spacing: normal;
	font-size: 16px;
	cursor: pointer;
	margin: 0;
}

.navbar-landing:first-child {
	margin-right: 0;
}


#form {
	padding: 0 40px;
}

/* .opinions_button{
	border-radius: 100px;
	background-color: #f90;
	text-transform: none;
	height: 57px;
	color: #FFF;
	font-size: 24px;
	font-family: 'Fredoka One', cursive;
} */

/* .no-webp .prices_background_home {
	background: url('https://cdn.fixat.mx/assets/home/bg/no-webp/bg_prices.jpeg');
	background-size: cover;
	background-position: center;
}

.webp .prices_background_home {
	background: url('https://cdn.fixat.mx/assets/home/bg/webp/bg_prices.webp');
	background-size: cover;
	background-position: center;
} */

.plus_icon,
.equals_icon {
	position: absolute;
	top: calc(50% - 1px);
	left: -20px;
	width: 32px;
	height: 35px;
	background-repeat: no-repeat;
}

.no-webp .plus_icon {
	background-image: url('https://cdn.fixat.mx/assets/bg/no-webp/plus_icon.png');
}

.webp .plus_icon {
	background-image: url('https://cdn.fixat.mx/assets/bg/webp/plus_icon.webp');
}

.no-webp .equals_icon {
	background-image: url('https://cdn.fixat.mx/assets/bg/no-webp/equals_icon.png');
}

.webp .equals_icon {
	background-image: url('https://cdn.fixat.mx/assets/bg/webp/equals_icon.webp');
}

.plus_icon {
	margin-top: -13px;
}

.equals_icon {
	margin-top: -3px;
}

/* .formula_robot {
	top: 25px;
} */

.weare_button {
	padding: 15px 10px !important;
}

@media screen and (max-width: 1024px) {
	#col-6-resize.col-6 {
		width: 100%;
		display: flex;
		justify-content: center;
	}

	.swiper-button-next,
	.swiper-button-prev {
		top: 82% !important;
		margin-top: 20px !important;
	}

	.mySwiper-opinions {
		height: auto !important;
	}
}

.swiper-button-prev-card {
	border-radius: 100%;
	font-size: 22px;
	color: #0F4373 !important;
	border-color: #0F4373;
	border-width: 1.8px;
	width: 54px !important;
	height: 54px !important;
	display: flex;
	@apply items-center justify-center;
	top: 90% !important;
	cursor: pointer;
	position: absolute;
	@apply z-10;
}

.swiper-button-next-card {
	position: absolute;
	border-radius: 100%;
	font-size: 22px;
	background-color: #0F4373 !important;
	color: white;
	border-width: 1.8px;
	width: 54px !important;
	height: 54px !important;
	display: flex;
	@apply items-center justify-center;
	top: 90% !important;
	cursor: pointer;
	@apply z-10;
}

.swiper-button-prev-card {
	left: calc(94.5% - 70px);
	@apply ssm:hidden mmd:left-[78%];
}

.swiper-button-next-card {
	left: 94.5%;
	@apply mmd:left-[90%] ssm:hidden;
}

.swiper-button-next-card::after,
.swiper-button-prev-card::after {
	content: "" !important;
}

/*-----------------THANKYOU PAGE ------------------------------------------------------------------ */
.no-webp #thankyou_main {
	background-image: url('https://cdn.fixat.mx/assets/bg/no-webp/fondo-thankyou-page.png');
	background-size: cover;
	height: auto;
	background-position: bottom;
	background-repeat: no-repeat;
}

.webp #thankyou_main {
	background-image: url('https://cdn.fixat.mx/assets/bg/webp/fondo-thankyou-page.webp');
	background-size: cover;
	height: auto;
	background-position: bottom;
	background-repeat: no-repeat;
}

.player-wrapper {
	position: relative;
	padding-top: 56.25%
		/* Player ratio: 100 / (1280 / 720) */
}

.react-player {
	position: absolute;
	top: 0;
	left: 0;
}

.no-webp #thankyou_background_process {
	background: url('https://cdn.fixat.mx/assets/bg/webp/background-asf1x.webp');
	width: 100%;
	-webkit-clip-path: ellipse(70% 90% at 50% 0%);
	clip-path: ellipse(70% 90% at 50% 0%);
	height: auto;
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;
}

.webp #thankyou_background_process {
	background: url('https://cdn.fixat.mx/assets/bg/webp/background-asf1x.webp');
	width: 100%;
	-webkit-clip-path: ellipse(70% 90% at 50% 0%);
	clip-path: ellipse(70% 90% at 50% 0%);
	height: auto;
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
}

/*   --------------------------------  Thank you Payment Page    -------------------------------- */
.no-webp #thanks_payment_bg {
	background: url('https://cdn.fixat.mx/assets/bg/no-webp/background-asf1x.png');
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
	height: auto;
}

.webp #thanks_payment_bg {
	background: url('https://cdn.fixat.mx/assets/bg/no-webp/background-asf1x.png');
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
	height: auto;
}

#congrats-section {
	background-image: url('https://cdn.fixat.mx/assets/bg/webp/Asesoria-fiscal-bg.webp');
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
	height: auto;
}

#service-process-section {
	background-image: url('https://cdn.fixat.mx/assets/home/bg/no-webp/bg_005.png');
	opacity: 0.9;
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
	height: auto;
}

#thankyou_payment_before_faqs {
	overflow: hidden;
	background: #dfeaf6;
	height: 100px;
}

#thankyou_payment_before_faqs::before {
	position: absolute;
	content: '';
	width: 100%;
	height: 220px;
	background: #145d9e;
	border-radius: 50%;
	top: 0;
	left: 0;
}

/*-----------PAYMENT OXXO---------------------*/

.no-webp #oxxo_header_background {
	background: url('https://cdn.fixat.mx/assets/bg/no-webp/Regularization-bg.png');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	border-bottom-left-radius: 22%;
	border-bottom-right-radius: 22%;
	height: 954px;
}

.webp #oxxo_header_background {
	background: url('https://cdn.fixat.mx/assets/bg/webp/Regularization-bg.webp');
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	-webkit-clip-path: ellipse(70% 90% at 50% 0%);
	clip-path: ellipse(100% 95% at 50% 0%);
	height: 954px;
}

.special_radial_gradient {
	background: radial-gradient(circle, #ff9900 0%, #ff9900 42%, #FFF 47%);
}

  
@media screen and (max-width: 768px) {

	/* ------------------------     THANK YOU PAGE      -------------------------------   */
	.webp #thankyou_background_process {
		-webkit-clip-path: ellipse(100% 90% at 50% 0%);
		clip-path: ellipse(100% 90% at 50% 0%);
		background-position: bottom left;
		background-size: auto;
	}

	.no-webp #thankyou_background_process {
		-webkit-clip-path: ellipse(100% 90% at 50% 0%);
		clip-path: ellipse(100% 90% at 50% 0%);
		background-position: center left;
		background-position: bottom left;
		background-size: auto;
	}

	.webp #thankyou_main {
		background: #104677;
	}

	.no-webp #thankyou_main {
		background: #104677;
	}
}

/*-----------------------     END THANK YOU PAGE -------------------------------  */

@media screen and (max-width: 768px) {
	.db .col-2:not(.not_resize) {
		width: 100%;
	}

	.buttons {
		display: inline-block;
		padding: 15px;
		cursor: pointer;
		line-height: 1.25;
		font-family: 'Fredoka One', cursive;
		position: relative;
		border: 0;
		border-radius: 100px;
		font-size: 20px;
		letter-spacing: 1.2;
	}

	.pw {
		max-width: 75%;
	}

	/* .webp #main_top {
		background: #ecf6ff;
	}

	.no-webp #main_top {
		background: #ecf6ff;
	} */

	#welcome_form_before_01::after {
		border-radius: 0%;
	}

	#section_002_after {
		height: 80px;
		margin-top: 40px;
	}

	#section_002_after:after {
		top: -37%;
		height: 62px;
	}

	.check_list li span {
		padding-left: 20px;
	}

	.check_list li span:before {
		content: '';
		position: absolute;
		width: 18px;
		height: 18px;
		background-image: url('https://cdn.fixat.mx/assets/home/bg/no-webp/check_icon.png');
		background-size: contain;
		background-repeat: no-repeat;
		top: 5px;
		left: 0px;
	}

	.check_list li {
		font-size: 20px;
		text-align: left;
	}

	/* #welcome_form_after {
		overflow: hidden;
		height: 149px;
	} */

	#main_top:after {
		visibility: hidden;
	}

	.slideshow-container {
		max-width: 1000px;
		position: relative;
		margin: auto;
	}

	.mySlides {
		display: none;
	}

	.mySwiper {
		height: 140px;
	}

	.swiper-pagination-bullet {
		width: 15px !important;
		height: 15px !important;
		background: #ff9900 !important;
	}

	.swiper-pagination-bullet-active {
		background: #ff9900 !important;
	}

	.swiper-button-next,
	.swiper-button-prev {
		top: 82% !important;
		margin-top: 21px !important;
	}

	.mySwiper-opinions {
		padding-bottom: 100px !important;
	}

	.formula_title {
		margin-top: 70px;
	}

	.equals_icon {
		margin-top: 20px;
		top: -50px;
		transform: translateX(-50%);
		left: 50%;
	}

	.plus_icon .plus_icon_helper {
		margin-top: -30px;
	}

	.plus_icon {
		top: -50px;
		transform: translateX(-50%);
		left: 50%;
	}

	.formula_robot {
		top: 0px;
		margin-bottom: 120px;
	}

	.formula_logo {
		margin-top: 30px;
		margin-bottom: 70px;
	}

	.mySwiper2 {
		height: 180px;
	}

	/* ------------------------     THANK YOU PAGE      -------------------------------   */
	.webp #thankyou_background_process {
		-webkit-clip-path: ellipse(100% 90% at 50% 0%);
		clip-path: ellipse(100% 90% at 50% 0%);
		background-position: bottom left;
		background-size: auto;
	}

	.no-webp #thankyou_background_process {
		-webkit-clip-path: ellipse(100% 90% at 50% 0%);
		clip-path: ellipse(100% 90% at 50% 0%);
		background-position: center left;
		background-position: bottom left;
		background-size: auto;
	}

	#thankyou_gray_wave {
		display: block;
		background-color: #e2ecf6;
		height: 80px;
		width: 100%;
		-webkit-clip-path: ellipse(60% 40% at 50% 100%);
		clip-path: ellipse(60% 40% at 50% 100%);
	}

	#thankyou_before_faqs {
		overflow: hidden;
		height: 35px;
	}

	#thankyou_before_faqs:after {
		position: absolute;
		content: '';
		width: 100%;
		height: 80px;
		background: #145d9e;
		border-radius: 90%;
		top: 0%;
		left: 0;
	}

	.thankyou_process_card_button {
		height: 114px;
		width: 100%;
		-webkit-backdrop-filter: blur(7px);
		backdrop-filter: blur(7px);
		background-color: rgba(255, 255, 255, 0.51);
		border-top-left-radius: 23px;
		border-top-right-radius: 23px;
		display: flex;
		justify-content: center;
		box-shadow: -3px 11px 25px 11px rgba(0, 0, 0, 0.14);
		-webkit-box-shadow: -3px 11px 25px 11px rgba(0, 0, 0, 0.14);
		-moz-box-shadow: -3px 11px 25px 11px rgba(0, 0, 0, 0.14);
	}

	/* ---------------------------------  OXXO PAYMENT  -------------------------- */

	.no-webp #oxxo_header_background {
		background: #165187;
		max-height: 904px;
		-webkit-clip-path: ellipse(70% 90% at 50% 0%);
		clip-path: ellipse(200% 100% at 50% 0%);
	}

	.webp #oxxo_header_background {
		background: #165187;
		max-height: 904px;
		-webkit-clip-path: ellipse(70% 90% at 50% 0%);
		clip-path: ellipse(200% 100% at 50% 0%);
	}

	/* ---------------------------------  THANK YOU PAYMENT  -------------------------- */
	.no-webp #thanks_payment_bg {
		background: #e7eff7;
	}

	.webp #thanks_payment_bg {
		background: #e7eff7;
	}

	#thankyou_payment_before_faqs {
		overflow: hidden;
		background: #dfeaf6;
		height: 45px;
	}

	#thankyou_payment_before_faqs::before {
		position: absolute;
		content: '';
		width: 100%;
		height: 87px;
		background: #145d9e;
		border-radius: 90%;
		top: 0%;
		left: 0;
	}
}

@media screen and (max-width: 650px) {
	.pw {
		max-width: 95%;
	}

	.check_list li span {
		line-height: 130%;
	}
}

@media screen and (max-width: 600px) {

	/* ------------------------     CHECKOUT PAGE      -------------------------------   */
	/* #payment_header_after{
        overflow: hidden;
        height: 49px;
    }
    #payment_header_after:after{
        position: absolute;
        content: '';
        width: 100%;
        height: 78px;
        background: #165187;
        border-radius: 90%;
        top: -100%;
        left: 0;
    } 

    #payment_header{
        max-height: 800px;
    }*/
}

@media screen and (max-width: 480px) {
	h1 {
		font-size: 25px
	}

	h2 {
		font-size: 21px
	}

	h3 {
		font-size: 19px
	}

	h4 {
		font-size: 15px
	}

	h5 {
		font-size: 13px
	}

	h6 {
		font-size: 13px
	}

	.hide_mobile {
		display: none
	}

	.button {
		font-family: 'Fredoka One', cursive;
	}

	.swiper-button-next,
	.swiper-button-prev {
		top: 81% !important;
		margin-top: 16px !important;
	}

	.swiper-button-prev {
		left: calc(40% - 48px) !important;
	}

	.swiper-button-next {
		left: 64% !important;
	}

	.mySwiper-opinions {
		padding-bottom: 60px !important;
	}

	.mySwiper2 {
		height: 180px;
		padding-bottom: 10px;
	}
}

@media screen and (max-width: 420px) {
	#form {
		padding: 0 20px;
	}
}



.quote-animation {
	object-fit: cover;
	overflow: hidden;
	width: 200px;
	height: 300px;
	width: 100%;
	position: relative;
}

.quote-animation>div {
	position: absolute;
	top: 35%;
	-webkit-transition: top 0.5s;
	-moz-transition: top 0.5s;
	-ms-transition: top 0.5s;
	-o-transition: top 0.5s;
	transition: top 0.5s;
}

.quote {
	padding-top: 124px;
	-webkit-transition: padding 0.5s;
	-moz-transition: padding 0.5s;
	-ms-transition: padding 0.5s;
	-o-transition: padding 0.5s;
	transition: padding 0.5s;
}

@media screen and (min-width: 950px) {
	.quote-animation:hover div {
		top: 1%;
		-webkit-transition: top 0.5s;
		-moz-transition: top 0.5s;
		-ms-transition: top 0.5s;
		-o-transition: top 0.5s;
		transition: top 0.5s;
	}


	.quote-animation:hover .quote {
		padding-top: 12px;
		-webkit-transition: padding 0.5s;
		-moz-transition: padding 0.5s;
		-ms-transition: padding 0.5s;
		-o-transition: padding 0.5s;
		transition: padding 0.5s;
	}
}

.quote-hide>div {
	position: absolute;
	top: 35%;
	-webkit-transition: top 0.5s;
	-moz-transition: top 0.5s;
	-ms-transition: top 0.5s;
	-o-transition: top 0.5s;
	transition: top 0.5s;
}

.quote-show>div {
	top: 1%;
	-webkit-transition: top 0.5s;
	-moz-transition: top 0.5s;
	-ms-transition: top 0.5s;
	-o-transition: top 0.5s;
	transition: top 0.5s;
}

.quote-show-card {
	padding-top: 12px;
	-webkit-transition: padding 0.5s;
	-moz-transition: padding 0.5s;
	-ms-transition: padding 0.5s;
	-o-transition: padding 0.5s;
	transition: padding 0.5s;
}

.quote-hide-card {
	padding-top: 124px;
	-webkit-transition: padding 0.5s;
	-moz-transition: padding 0.5s;
	-ms-transition: padding 0.5s;
	-o-transition: padding 0.5s;
	transition: padding 0.5s;
}
.visible-div{
	visibility: visible;
	opacity: 1;
	transition: opacity 0.5s linear;
}

.hidden-div{
	visibility: hidden;
	opacity: 0;
	transition: visibility 0s 0.2s, opacity 0.2s linear;
}

.visible-div,
.hidden-div {
  overflow: hidden;
  /* This container should not have padding, borders, etc. */
}

.hidden-div > div {
  margin-top: -10000px;
  transition: margin-top 0s 0.2s;
}